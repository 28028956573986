<template>
  <div id="add-event-image">
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>
          {{ pageTitle }}
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="600"
      >
        <v-container style="overflow: hidden">
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="submitData"
          >
            <v-card-text>
              <!-- <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    elevation="30"
                    shaped
                    tile
                    small
                    class="font-size-h6 mr-3 white--text"
                    @click.prevent="refreshPageData"
                    color="#a4c639"
                  >
                    Refresh
                  </v-btn>
                  <br />
                </v-col>
              </v-row> -->
              <v-container>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        <span class="text-danger">*</span> Document Type
                      </h6>
                    </label>
                    <v-select
                      :reduce="(option) => option.value"
                      :loading="DocumentTypeOptionsLoading"
                      :items="DocumentTypeOptions"
                      :rules="DocumentTypeRules"
                      v-model="DocumentType"
                      required
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    lg="4"
                    md="4"
                    v-if="DocumentType != ''"
                  >
                    <label>
                      <h6 class="mycard-text1 fsize-4">
                        Description <small> (optional)</small>
                      </h6>
                    </label>
                    <v-text-field
                      v-model="EventDescription"
                      :rules="EventDescriptionRules"
                      placeholder="Description"
                      hint="Enter image description"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row wrap v-if="DocumentType != ''">
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">
                      <span class="text-danger">*</span> Upload Image
                      <p class="notes-text1">
                        (only jpg, png image files are accepted)
                      </p>
                    </h6>
                    <v-file-input
                      :clearable="false"
                      truncate-length="25"
                      accept="image/png, image/jpeg"
                      placeholder="Upload Event Photo"
                      prepend-icon="mdi-card-bulleted-settings"
                      append-outer-icon="mdi-delete"
                      v-model="UploadImage1"
                      @change="addImage(1)"
                    >
                      <template v-slot:append-outer>
                        <v-icon color="red" @click="deleteImage(1)">
                          mdi-delete
                        </v-icon>
                      </template>
                    </v-file-input>
                  </v-col>
                  <v-col align="center" cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">Image Edit Options</h6>
                    <br />
                    <div class="actions">
                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="flip(true, false)"
                        >
                          <v-icon>fa fa-caret-right</v-icon>
                        </v-btn>
                      </b-button-group>

                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="flip(false, true)"
                        >
                          <v-icon>fa fa-caret-up</v-icon>
                        </v-btn>
                      </b-button-group>

                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="rotate(90)"
                        >
                          <v-icon>fa fa-redo</v-icon>
                        </v-btn>
                      </b-button-group>

                      <b-button-group vertical class="mr-3 mb-3">
                        <v-btn
                          fab
                          dark
                          small
                          color="indigo"
                          @click.prevent="rotate(-90)"
                        >
                          <v-icon>fa fa-undo</v-icon>
                        </v-btn>
                      </b-button-group>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="6" lg="4" md="4">
                    <h6 class="mycard-text1 fsize-4">
                      Uploaded Image will Appear Here
                    </h6>
                    <cropper
                      ref="cropper"
                      :src="MemberImage.ImageUrl"
                      :stencil-props="{
                        handlers: {},
                        movable: false,
                        scalable: false,
                      }"
                      :stencil-size="StencilSize"
                      image-restriction="stencil"
                    />
                  </v-col>
                </v-row>
                <v-row wrap v-if="DocumentType != ''">
                  <v-col align="center" cols="12" md="12">
                    <v-btn
                      @click="closePrompt"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#a52a2a"
                      class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      :disabled="!valid1"
                      @click.prevent="submitData"
                      :loading="SubmitFlag"
                      elevation="30"
                      shaped
                      tile
                      small
                      color="#1db954"
                      class="font-size-h6 fsize-3 mr-3 my-3 white--text"
                    >
                      Add
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-form>
        </v-container>
      </v-sheet>
      <v-card-actions>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    addRecordPrompt: {
      type: Boolean,
      required: true,
    },
    pageTitle: {
      type: String,
    },
    pageDescription: {
      type: String,
    },
  },
  data() {
    return {
      valid1: true,

      LoadingFlag: false,
      SubmitFlag: false,

      DocumentTypeRules: [(v) => !!v || "Document Type is required"],
      DocumentType: "",
      DocumentTypeOptions: [],
      DocumentTypeOptionsLoading: false,

      EventDescription: "",
      EventDescriptionRules: [],

      UploadImage1: {},
      MemberImage: { src: null },

      StencilSize: { width: 1360, height: 768 },
    };
  },
  computed: {},
  watch: {
    DocumentTypeOptions: function () {
      console.log("watch DocumentTypeOptions");
      this.LoadingFlag = false;
      this.DocumentTypeOptionsLoading = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    validateForm() {
      console.log("validateForm called");
      this.$refs.form1.validate();
    },
    resetForm() {
      console.log("resetForm called");
      this.$refs.form1.reset();
      this.closePrompt();
    },
    resetValidation() {
      console.log("resetValidation called");
      this.$refs.form1.resetValidation();
    },
    closePrompt(document = {}) {
      console.log("closePrompt called");
      this.$emit("hideAddDocumentRecordPrompt", document);
    },
    refreshPageData() {
      console.log("refreshPageData called");
      this.getDocumentTypeOptions();
    },
    addImage(id) {
      console.log("addImage called");
      console.log({ id });
      switch (id) {
        case 1:
          var file = this.UploadImage1;
          console.log({ file });
          var src = URL.createObjectURL(file);
          console.log({ src });
          if (this.MemberImage.src) {
            URL.revokeObjectURL(this.MemberImage.src);
          }
          var FileType = file.type;
          console.log({ FileType });
          this.MemberImage = {
            ImageName: file.name,
            ImageType: FileType,
            ImageUrl: src,
            UploadedImage: file,
          };
          break;

        default:
          break;
      }
    },
    deleteImage(id) {
      console.log("deleteImage called");
      console.log({ id });
      switch (id) {
        case 1:
          this.MemberImage = {
            ImageName: null,
            ImageType: null,
            ImageUrl: null,
            UploadedImage: null,
          };
          this.UploadImage1 = {};
          break;

        default:
          break;
      }
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    flip(x, y) {
      console.log("flip x=" + x + ", y=" + y);
      this.$refs.cropper.flip(x, y);
    },
    rotate(angle) {
      console.log("rotate=" + angle);
      this.$refs.cropper.rotate(angle);
    },
    getDocumentTypeOptions() {
      console.log("getDocumentTypeOptions called");
      this.DocumentTypeOptionsLoading = true;
      var selectbox1_source = "DocumentType";
      var selectbox1_destination = "DocumentTypeOptions";
      var selectbox1_url = "api/sub-category/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
        CategoryMainId: 16,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    submitData() {
      console.log("submitData is called");
      var message = "";
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        // Get Title details

        var DocumentType = this.DocumentType;
        var index1 = this.DocumentTypeOptions.map((e) => e.value).indexOf(
          DocumentType
        );
        console.log("DocumentType=" + DocumentType + ", index1=" + index1);
        var DocumentTypeName = this.DocumentTypeOptions[index1].text;
        console.log("DocumentTypeName=" + DocumentTypeName);
        const { canvas } = this.$refs.cropper.getResult();
        if (canvas) {
          var file = this.UploadImage1;
          var FileType = file.type;
          var ImageName = file.name;
          console.log({ FileType });
          console.log({ ImageName });
          //   const form = new FormData();
          canvas.toBlob((blob) => {
            // formData.append("ProfilePicture", blob, "logo.png");
            // You can use axios, superagent and other libraries instead here
            var tempData = {
              EventDocumentId: null,
              DocumentFlag: true,
              DocumentTypeId: DocumentType,
              DocumentTypeName: DocumentTypeName,
              DocumentDescription: this.EventDescription,
              ImageType: FileType,
              ImageUrl: canvas.toDataURL(),
              UploadedImage: blob,
              UploadedImageName: ImageName,
              UploadedFile: file,
              action: "",
            };
            console.log({ tempData });
            this.closePrompt(tempData);
            // Perhaps you should add the setting appropriate file format here
          }, FileType);
        } else {
          message += "Kindly select image. ";
          this.toast("error", message, true);
        }
      } else {
        if (validate1 == false) {
          message += "Kindly fill the required fields. ";
        }
        this.toast("error", message, true);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
#add-event-image {
  .mycard-text1 {
    font-family: sans-serif;
  }
  .fsize-4 {
    font-size: 18px !important;
  }
  .notes-text1 {
    padding: 5px;
    margin-left: 2rem;
    font-size: 14px;
    font-family: sans-serif;
  }
}
</style>